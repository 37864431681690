import gql from "graphql-tag";

export const MANAGE_REGIONS_POLYGONS_TREE_VIEW_UPDATE = gql`
    query manageRegionsPolygonsTreeViewUpdate(
        $v_regions_groups_guid: uuid,
        $v_sys_owners_guid: uuid,
        $v_tree_view_json: json,
        $v_locale: String,
        ){
        dal_add_task(args: {
            v_task_name: "manage_regions_polygons_tree_view_update",
            v_task_description: "Updates a region tree view JSON into selected polygons to display",
            v_task_params: [
            {
                param: "v_regions_groups_guid",
                param_type: "uuid",
                param_value: $v_regions_groups_guid,
            },
            {
                param: "v_sys_owners_guid",
                param_type: "uuid",
                param_value: $v_sys_owners_guid,
            },
            {
                param: "v_tree_view_json",
                param_type: "json",
                param_value: $v_tree_view_json,
            },
            {
                param: "v_locale",
                param_type: "character varying",
                param_value: "en",
            }
            ],
            v_tasks_guid: null,
            v_task_priority: 10,
            v_tasks_types_guid: "d90332f3-0f83-4cf3-97ae-04496ce84187"}){
                gql_results
                return_code
                return_code_locale
            }
    }`;

export const BASE_ADD_REGIONS_AND_REGIONS_LINKS = gql`
    query baseAddRegionsAndRegionsLinks(
        $v_regions_guid: uuid,
        $v_regions_name: String,
        $v_regions_types_guid: uuid,
        $v_sys_owners_guid: uuid,
        $v_parent_regions_guid: uuid,
        $v_parent_regions_types_guid: uuid,
        $v_regions_definition: String,
        $v_locale: String,
        ){
        dal_add_task(args: {
                v_task_name: "base_add_regions_and_regions_links",
                v_task_description: "Save region groups",
                v_task_params: [
                  {
                    param: "v_regions_guid",
                    param_type: "uuid",
                    param_value: $v_regions_guid,
                  },
                  {
                    param: "v_regions_name",
                    param_type: "character varying",
                    param_value: $v_regions_name,
                  },
                  {
                    param: "v_regions_types_guid",
                    param_type: "uuid",
                    param_value: $v_regions_types_guid,
                  },
                  {
                    param: "v_sys_owners_guid",
                    param_type: "uuid",
                    param_value: $v_sys_owners_guid,
                  },
                  {
                    param: "v_parent_regions_guid",
                    param_type: "uuid",
                    param_value: $v_parent_regions_guid,
                  },
                  {
                    param: "v_parent_regions_types_guid",
                    param_type: "uuid",
                    param_value: $v_parent_regions_types_guid,
                  },
                  {
                    param: "v_regions_definition",
                    param_type: "geometry",
                    param_value: $v_regions_definition,
                  },
                  {
                    param: "v_locale",
                    param_type: "character varying",
                    param_value: "en",
                  }
            ],
            v_tasks_guid: null,
            v_task_priority: 10,
            v_tasks_types_guid: "dfe4fbfc-c67e-4887-90fc-c87640256dce"}){
                gql_results
                return_code
                return_code_locale
            }
    }`;


export const SET_SYS_SCREENS_PANELS_VIEW_AS_USER_FAVOURITE = gql`
    query set_sys_screens_panels_view_as_user_favourite (
        $v_sys_screens_panels_guid: uuid,
        $v_sys_panels_views_guid: uuid,
        $v_locale: String,
        ){
        dal_add_task(args: {
                v_task_name: "set_sys_screens_panels_view_as_user_favourite",
                v_task_description: "Set screen's panel's view as user favourite",
                v_task_params: [
                  {
                    param: "v_sys_screens_panels_guid",
                    param_type: "uuid",
                    param_value: $v_sys_screens_panels_guid,
                  },
                  {
                    param: "v_sys_panels_views_guid",
                    param_type: "uuid",
                    param_value: $v_sys_panels_views_guid,
                  },
                  {
                    param: "v_locale",
                    param_type: "character varying",
                    param_value: "en",
                  }
            ],
            v_tasks_guid: null,
            v_task_priority: 10,
            v_tasks_types_guid: "2545e2bb-550c-4e56-8a31-ab010b3ef162"}){
                gql_results
                return_code
                return_code_locale
            }
    }`;


export const SYS_UPDATE_PANEL_VIEW_PIVOT = gql`
    query sys_update_panel_view_pivot (
        $v_sys_panels_views_guid: uuid,
        $v_pivot_json_definition: json,
        $v_locale: String,
        ){
        dal_add_task(args: {
                v_task_name: "sys_update_panel_view_pivot",
                v_task_description: "update panel view pivot",
                v_task_params: [
                  {
                    param: "v_sys_panels_views_guid",
                    param_type: "uuid",
                    param_value: $v_sys_panels_views_guid,
                  },
                  {
                    param: "v_pivot_json_definition",
                    param_type: "json",
                    param_value: $v_pivot_json_definition,
                  },
                  {
                    param: "v_locale",
                    param_type: "character varying",
                    param_value: "en",
                  }
            ],
            v_tasks_guid: null,
            v_task_priority: 10,
            v_tasks_types_guid: "deabfc2c-f4fe-41df-b06b-579ed36b8242"}){
                gql_results
                return_code
                return_code_locale
            }
    }`;

export const SYS_UPDATE_PANEL_VIEW_MAP = gql`
    query sys_update_panel_view_map (
        $v_sys_panels_views_guid: uuid,
        $v_centroid: geometry,
        $v_zoom: Int,
        $v_sys_dynamic_datasources_guid: uuid,
        $v_image: String,
        $v_real_size: Int,
        $v_locale: String,
        ){
        dal_add_task(args: {
                v_task_name: "sys_update_panel_view_map",
                v_task_description: "update panel view pivot",
                v_task_params: [
                  {
                    param: "v_sys_panels_views_guid",
                    param_type: "uuid",
                    param_value: $v_sys_panels_views_guid,
                  },
                  {
                    param: "v_centroid",
                    param_type: "geometry",
                    param_value: $v_centroid,
                  },
                  {
                    param: "v_zoom",
                    param_type: "Int",
                    param_value: $v_zoom,
                  },
                  {
                    param: "v_sys_dynamic_datasources_guid",
                    param_type: "uuid",
                    param_value: $v_sys_dynamic_datasources_guid
                  },
                  {
                    param: "v_image",
                    param_type: "character varying",
                    param_value: $v_image
                  },
                  {
                    param: "v_real_size",
                    param_type: "numeric",
                    param_value: $v_real_size
                  },
                  {
                    param: "v_locale",
                    param_type: "character varying",
                    param_value: $v_locale
                  }
            ],
            v_tasks_guid: null,
            v_task_priority: 10,
            v_tasks_types_guid: "2b3e612e-52ef-447f-a8a1-27be31d05546"}){
                gql_results
                return_code
                return_code_locale
            }
    }`;

export const SYS_UPDATE_PANEL_VIEW_REPORT = gql`
    query sys_update_panel_view_report (
        $v_sys_panels_views_guid: uuid,
        $v_report_json_definition: json,
        $v_locale: String,
        ){
        dal_add_task(args: {
                v_task_name: "sys_update_panel_view_report",
                v_task_description: "update panel view report",
                v_task_params: [
                  {
                    param: "v_sys_panels_views_guid",
                    param_type: "uuid",
                    param_value: $v_sys_panels_views_guid,
                  },
                  {
                    param: "v_report_json_definition",
                    param_type: "json",
                    param_value: $v_report_json_definition,
                  },
                  {
                    param: "v_locale",
                    param_type: "character varying",
                    param_value: $v_locale
                  }
            ],
            v_tasks_guid: null,
            v_task_priority: 10,
            v_tasks_types_guid: "8c656f34-eee5-42a3-a37e-22c44e2fefc1"}){
                gql_results
                return_code
                return_code_locale
            }
    }`;

export const SYS_ADD_PANEL_VIEW_PIVOT = gql`
    query sys_add_panel_view_pivot (
        $v_sys_panels_guid: uuid,
        $v_sys_owners_guid: uuid,
        $v_view_name: String,
        $v_pivot_json_definition: json,
        $v_locale: String,
        ){
        dal_add_task(args: {
                v_task_name: "sys_add_panel_view_pivot",
                v_task_description: "sys add panel view pivot",
                v_task_params: [
                  {
                    param: "v_sys_panels_guid",
                    param_type: "uuid",
                    param_value: $v_sys_panels_guid,
                  },
                  {
                    param: "v_sys_owners_guid",
                    param_type: "uuid",
                    param_value: $v_sys_owners_guid
                  },
                  {
                    param: "v_view_name",
                    param_type: "character varying",
                    param_value: $v_view_name
                  },
                  {
                    param: "v_pivot_json_definition",
                    param_type: "json",
                    param_value: $v_pivot_json_definition,
                  },
                  {
                    param: "v_locale",
                    param_type: "character varying",
                    param_value: "en",
                  }
            ],
            v_tasks_guid: null,
            v_task_priority: 10,
            v_tasks_types_guid: "c76ced16-ab3d-49a6-8353-6dae26993452"}){
                gql_results
                return_code
                return_code_locale
            }
    }`;

export const SYS_ADD_PANEL_VIEW_REPORT = gql`
    query sys_add_panel_view_report (
        $v_sys_panels_guid: uuid,
        $v_sys_owners_guid: uuid,
        $v_view_name: String,
        $v_report_json_definition: json,
        $v_locale: String,
        ){
        dal_add_task(args: {
                v_task_name: "sys_add_panel_view_report",
                v_task_description: "sys add panel view report",
                v_task_params: [
                  {
                    param: "v_sys_panels_guid",
                    param_type: "uuid",
                    param_value: $v_sys_panels_guid,
                  },
                  {
                    param: "v_sys_owners_guid",
                    param_type: "uuid",
                    param_value: $v_sys_owners_guid
                  },
                  {
                    param: "v_view_name",
                    param_type: "character varying",
                    param_value: $v_view_name
                  },
                  {
                    param: "v_report_json_definition",
                    param_type: "json",
                    param_value: $v_report_json_definition,
                  },
                  {
                    param: "v_locale",
                    param_type: "character varying",
                    param_value: "en",
                  }
            ],
            v_tasks_guid: null,
            v_task_priority: 10,
            v_tasks_types_guid: "1b06eb88-28e6-48e2-831b-ece071308566"}){
                gql_results
                return_code
                return_code_locale
            }
    }`;

export const SYS_ADD_PANEL_VIEW_MAP = gql`
    query sys_add_panel_view_pivot (
        $v_sys_panels_guid: uuid,
        $v_sys_owners_guid: uuid,
        $v_view_name: String,
        $v_centroid: geometry,
        $v_zoom: Int,
        $v_sys_dynamic_datasources_guid: uuid,
        $v_image: String,
        $v_real_size: Int,
        $v_locale: String,
        ){
        dal_add_task(args: {
                v_task_name: "sys_add_panel_view_map",
                v_task_description: "sys add panel view map",
                v_task_params: [
                  {
                    param: "v_sys_panels_guid",
                    param_type: "uuid",
                    param_value: $v_sys_panels_guid,
                  },
                  {
                    param: "v_sys_owners_guid",
                    param_type: "uuid",
                    param_value: $v_sys_owners_guid
                  },
                  {
                    param: "v_view_name",
                    param_type: "character varying",
                    param_value: $v_view_name
                  },
                  {
                    param: "v_centroid",
                    param_type: "geometry",
                    param_value: $v_centroid,
                  },
                  {
                    param: "v_zoom",
                    param_type: "Int",
                    param_value: $v_zoom,
                  },
                  {
                    param: "v_sys_dynamic_datasources_guid",
                    param_type: "uuid",
                    param_value: $v_sys_dynamic_datasources_guid
                  },
                  {
                    param: "v_image",
                    param_type: "character varying",
                    param_value: $v_image
                  },
                  {
                    param: "v_real_size",
                    param_type: "numeric",
                    param_value: $v_real_size
                  },
                  {
                    param: "v_locale",
                    param_type: "character varying",
                    param_value: $v_locale,
                  }
            ],
            v_tasks_guid: null,
            v_task_priority: 10,
            v_tasks_types_guid: "1ba4fc0d-ca69-4df8-a73f-fbdbf25ae57c"}){
                gql_results
                return_code
                return_code_locale
            }
    }`;


export const SYS_DELETE_PANELS_VIEW = gql`
    query delete_sys_panels_view (
        $v_sys_panels_views_guid: uuid,
        $v_sys_owners_guid: uuid,
        $v_locale: String,
        ){
        dal_add_task(args: {
                v_task_name: "delete_sys_panels_view",
                v_task_description: "procedure to delete a panel view",
                v_task_params: [
                  {
                    param: "v_sys_panels_views_guid",
                    param_type: "uuid",
                    param_value: $v_sys_panels_views_guid,
                  },
                  {
                    param: "v_sys_owners_guid",
                    param_type: "uuid",
                    param_value: $v_sys_owners_guid
                  },
                  {
                    param: "v_locale",
                    param_type: "character varying",
                    param_value: $v_locale,
                  }
            ],
            v_tasks_guid: null,
            v_task_priority: 10,
            v_tasks_types_guid: "e801444d-ba10-4978-9544-b1378f5b4655"}){
                gql_results
                return_code
                return_code_locale
            }
    }`;


export const RENAME_SYS_PANELS_VIEW = gql`
    query rename_sys_panels_view (
        $v_sys_panels_views_guid: uuid,
        $v_sys_owners_guid: uuid,
        $v_view_name: String,
        $v_locale: String,
        ){
        dal_add_task(args: {
                v_task_name: "rename_sys_panels_view",
                v_task_description: "procedure to rename a panel view",
                v_task_params: [
                  {
                    param: "v_sys_panels_views_guid",
                    param_type: "uuid",
                    param_value: $v_sys_panels_views_guid,
                  },
                  {
                    param: "v_sys_owners_guid",
                    param_type: "uuid",
                    param_value: $v_sys_owners_guid
                  },
                  {
                    param: "v_view_name",
                    param_type: "character varying",
                    param_value: $v_view_name
                  },
                  {
                    param: "v_locale",
                    param_type: "character varying",
                    param_value: $v_locale,
                  }
            ],
            v_tasks_guid: null,
            v_task_priority: 10,
            v_tasks_types_guid: "1067bd05-d0b7-4d73-8a35-98ed9624ba1d"}){
                gql_results
                return_code
                return_code_locale
            }
    }`;