import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import authService from '../services/AuthService';
import {
    getUserName,
    getUserMenus,
    getSysMenusList,
    GET_ALERTS,
    GET_USER_NAME_EMAIL,
    GET_APP_LOGO
} from '../apollo/queries';
import { useApolloClient } from "@apollo/react-hooks";
import {
    IconButton,
    Typography,
} from '@mui/material';
import { Alerts } from './Alerts'
import { SettingsPanel } from './SettingsPanel';
import { ScreenBar } from './ScreenBar';
import { SidebarContext, ScreenNameContext } from '../pages/dynamicScreens/Main/context';
import settingsIcon from '../assets/settings-icon.svg';
import { Popover } from './Popover'
import { GlobalTheme } from '../context/GlobalTheme';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { TreeviewDialogContext } from '../context/TreeviewDialogContext';
import { useDispatch, useSelector } from 'react-redux';


const drawerWidth = 260;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => {
        const globalTheme = useContext(GlobalTheme)

        return ({
            '& .MuiDrawer-paper': {
                position: 'relative',
                whiteSpace: 'nowrap',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                boxSizing: 'border-box',
                ...(!open && {
                    overflowX: 'hidden',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    width: theme.spacing(7),
                    [theme.breakpoints.up('sm')]: {
                        width: theme.spacing(9),
                    },
                }),
            },
            '.css-12i7wg6-MuiPaper-root-MuiDrawer-paper': {
                background: globalTheme[globalTheme.activeTheme]?.background,
            }
        })
    },
);

function Sidebar(props) {
    const user = JSON.parse(localStorage.getItem('user'));
    const [tenantUserGuid, tenantGuid] = [user.user_uid, user.tenant.tenants_guid]
    const locale = localStorage.getItem('locale');
    const client = useApolloClient();
    const { dialogIsOpen, openTreeviewDialog } = useContext(TreeviewDialogContext);
    const [open, setOpen] = useState(true);
    const history = useNavigate();
    const [menuList, setMenuList] = useState();
    const [menu, setMenus] = useState('');
    const [userName, setUserName] = useState();
    const [alerts, setAlerts] = useState([])
    const screenNameContext = useContext(ScreenNameContext);
    const [userNameEmail, setUserNameEmail] = useState('')
    const [appLogo, setAppLogo] = useState('')
    const [selectedMenu, setSelectedMenu] = useState('')
    const [fields, setFields] = useState({ dataSource: menu, id: 'users_menus_items_guid', text: 'menu_name', child: 'sections', iconCss: 'icon_name', })
    const [activeNode, setActiveNode] = useState(null);
    const globalTheme = useContext(GlobalTheme)
    const isParamenterScreenRequired = useSelector((state) => state.mainReducer.isParamenterScreenRequired);

    const getNewMenusList = async () => {
        const { data } = await client.query({ query: getSysMenusList, variables: { locale: locale, apps_type_uid: "5959df7f-5990-4d7b-9f33-797ed166c687", tenants_uid: user.tenant.tenants_guid } })
        setMenuList(data.dal_get_sys_menus_list)
        if (data.dal_get_sys_menus_list[0]) {
            _getNewMenus(data.dal_get_sys_menus_list[0])
        }
    }

    const _getNewMenus = async (event) => {
        let menuValue = []
        if (event?.target?.value) {
            menuValue = event.target.value
        }
        else {
            menuValue = event
        }
        setFields(prevFields => ({ ...prevFields, dataSource: "" }));
        setSelectedMenu(menuValue)
        const { data } = await client.query({ query: getUserMenus, variables: { locale: locale, apps_types_uid: "5959df7f-5990-4d7b-9f33-797ed166c687", menus_uid: menuValue.sys_menus_guid, tenants_uid: user.tenant.tenants_guid, users_uid: user.user_uid } })
        const newArray = []
        const newSections = []
        // console.log(data)

        data.dal_get_apps_menu[0].gql_results.forEach((l, idx) => {
            newArray[idx] = { ...l }
            if (l.sections !== null) {
                l.sections.forEach((s, index) => {
                    newSections[index] = { ...s}
                })
            }
        })
        const newMenuArray = newArray.map((l) => {
            l.menus_items_json = typeof (l.menus_items_json) !== 'string' ? '' : JSON.parse(l.menus_items_json)
            if (l.sections !== null) {
                l.sections.forEach((s, index) => {
                    newSections[index] = { ...s}
                })
            }
            return l
        })
        setMenus(newMenuArray)
        setFields(prevFields => ({ ...prevFields, dataSource: newMenuArray }))

    }

    useEffect(() => {
        const getAlerts = async () => {
            const { data } = await client.query({ query: GET_ALERTS })
            if (data) {
                setAlerts(data.alerts);
            }

        }

        getAlerts()

    }, [screenNameContext])

    const getUserNameEmail = async () => {
        const data = await client.query({
            query: GET_USER_NAME_EMAIL,
            variables: {
                locale: 'en',
                v_tenants_guid: tenantGuid,
                _eq: tenantUserGuid
            }
        })
        setUserNameEmail(data?.data?.dal_get_users_list?.[0]?.user_name)
    }

    const getAppLogo = async () => {
        const { data } = await client.query({ query: GET_APP_LOGO })
        setAppLogo(data?.dal_get_sys_application_logo[0].logo)
    }

    useEffect(() => {
        getUserNameEmail()
        getAppLogo()
        getNewMenusList()
    }, [])

    useEffect(() => {
        const _getUserName = async () => {
            const { data } = await client.query({ query: getUserName, variables: { users_uid: user.user_uid } })
            if (data?.users_by_pk?.user_name) {
                setUserName(data?.users_by_pk?.user_name)
            }
        }
        if (!userName)
            _getUserName()
    }, [])

    useEffect(() => {
        updateActiveNodeStyle();
    }, [globalTheme]);

    useEffect(( ) => {
        if(isParamenterScreenRequired) {
            setFilterState({
                right: true,
            })
        }
        
    }, [isParamenterScreenRequired])

    function logOut() {
        authService.logout();
        localStorage.removeItem('theme')
        history('/login');
    }

    const updateActiveNodeStyle = () => {
        // Update the style of the active node when the global theme changes
        if (activeNode) {
            activeNode.style.backgroundColor = globalTheme[globalTheme.activeTheme]?.headerBackground;
            activeNode.style.borderColor = globalTheme[globalTheme.activeTheme]?.headerBackground;
        }
    };

    const goToPage = (event) => {
        if (activeNode) {
            activeNode.style.backgroundColor = 'transparent';
            activeNode.style.borderColor = 'transparent';
        }

        let clickedNode = event.node.firstChild;

        if (clickedNode) {
            let wrapper = event.node.childNodes[1].classList.contains('e-icon-wrapper');
            if (wrapper) {
                clickedNode.style.backgroundColor = 'transparent'
                clickedNode.style.borderColor = 'transparent'
            }
            else {
                clickedNode.style.backgroundColor = globalTheme[globalTheme.activeTheme]?.headerBackground;
                clickedNode.style.borderColor = globalTheme[globalTheme.activeTheme]?.headerBackground;
                // Update the currently active node
                setActiveNode(clickedNode);
            }

        }
        if (event.node.dataset.uid === '87a71026-e9c3-4eec-bfba-daecf1c72e1c') {
            openTreeviewDialog(false)
            return history('/mapping/manage-regions')
        }
        if (event.node.dataset.uid === '43d57bf1-20e3-44f5-ac2c-caa18df29ecf') {
            openTreeviewDialog(false)
            return history('/mapping/manage-maps')
        }
        if (event.node.dataset.uid === '40051c68-2915-4af1-897b-572bb55d2364') {
            openTreeviewDialog(false)
            return history('/users-list')
        }

        if (menu.find(m => m.users_menus_items_guid === event.node.dataset.uid) !== undefined) {
            const menuObj = menu.find(m => m.users_menus_items_guid === event.node.dataset.uid)
            if (menuObj.users_screens_guid !== null) {
                const screenUid = menuObj.users_screens_guid
                openTreeviewDialog(false)
                return history(`/${screenUid}`)
            }
        } else {
            const menuObj = menu.find(m => event.node.parentNode.parentNode.dataset.uid === m.users_menus_items_guid);
            const sectionObj = menuObj?.sections.find(s => s.users_menus_items_guid === event.node.dataset.uid);
            openTreeviewDialog(false)
            return history(`/${sectionObj.users_screens_guid}`)
        }

    }

    const [filterState, setFilterState] = React.useState({
        right: false,
    });

    const [settingsState, setSettingsState] = React.useState({
        right: false,
    });

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <CssBaseline />
            <AppBar
                position="absolute" open={open}>
                <Toolbar
                    sx={{
                        bgcolor: globalTheme[globalTheme.activeTheme]?.headerBackground,
                        pr: '24px', // keep right padding when drawer closed
                        minHeight: '50px !important'
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h6"
                        noWrap
                        sx={{ flexGrow: 1, color: globalTheme[globalTheme.activeTheme]?.color }}
                    >
                        {screenNameContext?.screenName}
                    </Typography>
                    <ScreenBar
                        filterState={filterState}
                        setFilterState={setFilterState}
                        settingsState={settingsState}
                        setSettingsState={setSettingsState}
                    />
                </Toolbar>
            </AppBar>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', background: globalTheme[globalTheme.activeTheme]?.background }}>
                <Drawer style={{ height: '100%' }} variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            px: [1],
                            bgcolor: globalTheme[globalTheme.activeTheme]?.headerBackground,
                            minHeight: '50px !important'
                        }}
                    >
                        {
                            appLogo ?
                                <img src={`${appLogo}`}
                                    height='25px'
                                    style={{ margin: '0 5px 0 20px' }}
                                    alt="app-logo"
                                />
                                : null
                        }

                    </Toolbar>
                    <Divider sx={{ color: 'currentColor' }} />
                    <FormControl fullWidth>
                        <Select
                            input={<OutlinedInput />}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedMenu}
                            label="Menu List"
                            onChange={_getNewMenus}
                        >
                            {menuList && menuList.map((list, index) => (
                                <MenuItem key={index} value={list}>{list.menu_name}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                    <div style={{ overflow: 'auto', height: 'calc(100vh - 171px)', width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ flex: '1 1 auto' }}>
                            <List sx={{ height: '100%', width: 'fit-content', }} >
                                <TreeViewComponent
                                    style={{
                                        background: globalTheme[globalTheme.activeTheme]?.background,
                                    }}
                                    id="mainTree"
                                    cssClass="main-treeview"
                                    fields={fields}
                                    data-theme={globalTheme?.activeTheme}
                                    expandOn="Click"
                                    nodeClicked={goToPage}
                                />
                            </List>
                        </div>
                    </div>

                    <Divider sx={{ color: 'currentColor' }} />
                    <div className="sidebar-menu" style={{ background: globalTheme[globalTheme.activeTheme]?.headerBackground }}>
                        <Typography style={{ color: globalTheme[globalTheme.activeTheme]?.color, inlineSize: 120, whiteSpace: 'break-spaces' }} component="div">
                            <Box sx={{ fontWeight: 500, fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', height: '16px', whiteSpace: 'nowrap' }} title={user?.tenant.tenant_name}>{user?.tenant.tenant_name}</Box>
                            <Box sx={{ fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', height: '18px', whiteSpace: 'nowrap' }} title={userNameEmail}>{userNameEmail}</Box>
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Alerts alerts={alerts} setAlerts={setAlerts} />
                            <Popover logOut={logOut} alerts={alerts} />
                        </div>
                    </div>
                </Drawer>
            </div>
            {props.children}
        </Box>
    );
}


export default Sidebar;