import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/Home";
import LoginPage from "../pages/auth/Login";
import DynamicScreens from "../pages/dynamicScreens/Main";
import EditReport from "../pages/dynamicScreens/editReport";
import MappingLayout from "../mapping/layout/layout";
import AwsTest from "../pages/dynamicScreens/awsTest";
import { UsersList, AccountList } from '../pages/UsersList';
import { TenantsGrid } from "../pages/Tenants/Main";
import { TenantsEditGrid } from "../pages/Tenants/TenantsListEdit";
import { UsersEditGrid } from "../pages/Tenants/UsersListEdit";

function SynRouter() {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/:id" element={<DynamicScreens />} />
            <Route path="/edit-report" element={<EditReport />} />
            <Route path="mapping/*" element={<MappingLayout />} />
            <Route path="/users-list" element={<UsersList />} />
            <Route path="/account" element={<AccountList />} />
            <Route path="/manage-tenants" element={<TenantsGrid />} />
            <Route path="/add-tenants" element={<TenantsEditGrid />} />
            <Route path="/add-users" element={<UsersEditGrid />} />
            <Route path="/aws-test" element={<AwsTest />} />

        </Routes>
    );
}

export default SynRouter;
