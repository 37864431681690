import React, { useRef, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useForm, Controller } from "react-hook-form";

const Error = ({ children }) => <div style={{ color: 'red' }}>{children}</div>;

const ManageViewsSaveAsDialogContent = () => {
    const dispatch = useDispatch();

    const { handleSubmit, control, formState: { errors }, reset, getValues  } = useForm();
    

    const onSubmit = (data) => {
        console.log('onSubmit')
        dispatch({ type: 'CHANGE_MANAGE_VIEWS_VIEW_NAME', payload: data.manageViewsViewName })
        dispatch({ type: 'CLICK_MANAGE_VIEWS_SAVE_AS_DIALOG_SAVE_BUTTON', payload: true })
        dispatch({ type: 'TOGGLE_MANAGE_VIEWS_SAVE_AS_DIALOG', payload: false })
        reset();
    }

    const closeMapDialog = () => {
        dispatch({ type: 'TOGGLE_MANAGE_VIEWS_SAVE_AS_DIALOG', payload: false })
        reset();
    }

    const handleSaveClick = () => {
        const data = getValues();
        onSubmit(data);
    };

    return (
        <div className="wrapper">
            <section>
                <label className='my-2'>New View Name</label>
                <Controller
                    name="manageViewsViewName"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                        <TextBoxComponent
                            placeholder="Enter View Name"
                            // floatLabelType="Auto"
                            change={({ value }) => field.onChange(value)}
                            value={field.value}
                        />
                    )}
                />
                {errors.manageViewsViewName && <Error>This field is required</Error>}
            </section>
            <div className="d-flex justify-content-between my-2">
                <ButtonComponent onClick={handleSaveClick} type="submit" cssClass='e-primary'>
                    Save
                </ButtonComponent>
                <ButtonComponent onClick={closeMapDialog} cssClass='e-outline'>
                    Cancel
                </ButtonComponent>
            </div>
        </div>
    )
}

export default ManageViewsSaveAsDialogContent;