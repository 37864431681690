import * as actionTypes from './manageViewsActions';

export const initialState = {
    isManageEditButtonCLicked: false,
    isManageSaveButtonCLicked: false,
    isManageDeleteButtonClicked: false,
    isManageViewsSaveAsDialogOpen: false,
    isManageViewsRenameDialogOpen: false,
    isManageVewsSaveAsDialogSaveButtonClicked: false,
    isManageVewsRenameDialogSaveButtonClicked: false,
    isManageViewRenameButtonClicked: false,
    manageViewsViewName: null,
    manageViewsPanelsGuid: null,
};

const manageViewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_MANAGE_VIEWS_EDIT_BUTTON:
            return { ...state, isManageEditButtonCLicked: action.payload };
        case actionTypes.TOGGLE_MANAGE_VIEWS_SAVE_BUTTON:
            return { ...state, isManageSaveButtonCLicked: action.payload };
        case actionTypes.CLICK_MANAGE_VIEWS_DELETE_BUTTON:
            return { ...state, isManageDeleteButtonClicked: action.payload };
        case actionTypes.TOGGLE_MANAGE_VIEWS_SAVE_AS_DIALOG:
            return { ...state, isManageViewsSaveAsDialogOpen: action.payload };
        case actionTypes.TOGGLE_MANAGE_VIEWS_RENAME_DIALOG:
            return { ...state, isManageViewsRenameDialogOpen: action.payload };
        case actionTypes.CLICK_MANAGE_VIEWS_SAVE_AS_DIALOG_SAVE_BUTTON:
            return { ...state, isManageVewsSaveAsDialogSaveButtonClicked: action.payload };
        case actionTypes.CLICK_MANAGE_VIEWS_RENAME_DIALOG_SAVE_BUTTON:
            return { ...state, isManageVewsRenameDialogSaveButtonClicked: action.payload };
        case actionTypes.CHANGE_MANAGE_VIEWS_VIEW_NAME:
            return { ...state, manageViewsViewName: action.payload };
        case actionTypes.SAVE_MANAGE_VIEWS_PANELS_GUID:
            return { ...state, manageViewsPanelsGuid: action.payload };
        case actionTypes.RENAME_MANAGE_VIEWS_PANELS_VIEW:
            return { ...state, isManageViewRenameButtonClicked: action.payload };

        default:
            return state;
    }
};

export default manageViewsReducer;
