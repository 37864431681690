import React, { useState, useEffect, useRef } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

const PointDialogComponent = ({ dialogVisible, closeDialog, value, onUpdateValue }) => {
    const mapRef = useRef(null);
    const mapContainerRef = useRef(null);
    const markerRef = useRef(null);
    const [pointCoordinates, setPointCoordinates] = useState([]);

    const pointDialogHeader = () => {
        const onUpdate = () => {
            // Implement your update logic here
            if (pointCoordinates !== null) {
                const { lat, lng } = markerRef.current.getLatLng();
                const newValue = `POINT(${lng} ${lat})`;
                onUpdateValue(newValue)
            }
            else {
                onUpdateValue(pointCoordinates)
            }

        };

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Point editor</span>
                <ButtonComponent onClick={onUpdate} cssClass="e-primary">Confirm</ButtonComponent>
            </div>
        )
    }

    useEffect(() => {
        if (dialogVisible && mapContainerRef.current) {
            if (!mapRef.current) {
                setTimeout(function () {
                    mapRef.current = L.map(mapContainerRef.current).setView([51.505, -0.09], 13);
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        attribution: '&copy; OpenStreetMap contributors'
                    }).addTo(mapRef.current)
                    mapRef.current.pm.addControls({
                        position: 'topleft',
                        drawMarker: true, // Enable marker drawing
                        dragMode: true, // Enable dragging of existing markers
                        drawCircleMarker: false,
                        drawPolyline: false,
                        drawText: false,
                        drawCircle: false,
                        cutPolygon: false,
                        drawPolygon: false,
                        drawPolyline: false,
                        drawRectangle: false,
                        drawCircle: false,
                        editMode: false,
                        deleteLayer: true,
                        rotateMode: false,
                    });

                    // Handle marker creation
                    mapRef.current.on('pm:create', (event) => {
                        if (event.shape === 'Marker') {
                            if (markerRef.current) {
                                // Remove the previous marker if it exists
                                mapRef.current.removeLayer(markerRef.current);
                            }
                            // Add the new marker
                            markerRef.current = event.layer;
                            setPointCoordinates(markerRef.current.getLatLng())
                        }
                    });

                    mapRef.current.on('pm:remove', (event) => {
                        if (event.shape === 'Marker') {
                            setPointCoordinates(null)
                        }
                    });



                    if (value) {
                        const match = value.match(/POINT\(([-\d.]+)\s([-\d.]+)\)/);
                        if (match) {
                            const [lng, lat] = [parseFloat(match[1]), parseFloat(match[2])];
                            const latLng = L.latLng(lat, lng);
                            markerRef.current = L.marker(latLng).addTo(mapRef.current);
                            mapRef.current.setView(latLng, 13);
                        }
                    }
                    else if (value === null || !value) {
                        mapRef.current.setView([0, 0], 1);
                    }
                }, 200);
            }
        }
    }, [dialogVisible]);

    return (
        <DialogComponent
            showCloseIcon={true}
            width="500px"
            height="500px"
            visible={dialogVisible}
            close={closeDialog}
            header={pointDialogHeader}
        // style={{marginTop: '40px'}}
        >
            <div ref={mapContainerRef} id="map" style={{ height: '100%', width: '100%' }}></div>
        </DialogComponent>
    )
}

export default PointDialogComponent;